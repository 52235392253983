import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';
import Img from 'gatsby-image';
import {graphql, useStaticQuery} from "gatsby";
import checkMark from '../assets/images/checkmark.svg';

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);
const ProjectContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ProjectItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-item: center;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-direction: column;
    }
`;

const ProjectItemContent = styled.div`
    flex-basis: 50%;
    background: #FFF;
    color: #202020;
    padding: var(--spacing-xl);
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-basis: 100%;
        order: 1;
        padding: var(--spacing-l);
    }
`;
const ProjectItemImg = styled.div`
    flex-basis: 50%;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-basis: 100%;
        order: 2;
    }
`;

const ProjectItemTitle = styled.h2`
    font-size: 2rem;
    font-family: var(--font-large);
    color: #202020;
    margin-bottom: var(--spacing-m);
    text-align: left;
`;

const ProjectItemDescription = styled.p`
    font-size: 1rem;
    font-family: var(--font-small);
    color: #202020;
    margin-bottom: var(--spacing-m);
    text-align: left;
`;

const ProjectSellingpoints = styled.ul`
    list-style: none inside none;
    display: flex;
    flex-direction: column;
`;

const ProjectSellingpoint = styled.li`
    background: url(${checkMark}) no-repeat left center;
    background-size: 20px 20px;
    font-size: 1rem;
    padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-l);
    color: #202020;
    font-family: var(--font-small);
`;

const ProjectButtons = styled.div`
    margin: var(--spacing-m) 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-direction: column;
        
        .primary, .normal {
            margin-top: var(--spacing-m);
        }
    }
    
    .primary {
      display: block;
      font-family: var(--font-small);
      font-size: 1rem;
      padding: var(--spacing-s) var(--spacing-m);
      text-align: center;
      text-decoration: none;
      width: auto;
      background-color: #0AA81C;
      color: var(--primary-color);
      border: 2px solid #0AA81C;
      border-radius: 2px;
      color: #FFF;
      cursor: pointer;
      transition: backgroundColor .4s, borderColor .2s;
      
      &:hover {
        background-color: transparent;
        color: #0AA81C;
      }
    }
    .normal {
        display: block;
      font-family: var(--font-small);
      font-size: 1rem;
      padding: var(--spacing-s) var(--spacing-m);
      text-align: center;
      text-decoration: none;
      width: auto;
      background-color: transparent;
      color: #202020;
      border: 2px solid #202020;
      border-radius: 2px;
      cursor: pointer;
      transition: backgroundColor .4s, borderColor .2s;
      
      &:hover {
        background-color: transparent;
        color: #0AA81C;
      }
    }
`



export default function ProjectOverview(node) {
    const { projectCategories } = useStaticQuery(graphql`
        query allProjects {
            projectCategories: allSanityCategory(sort: {fields: order, order: ASC})  {
                nodes {
                  title
                  sellingpoints
                  image {
                    asset {
                        fluid(maxWidth: 600, maxHeight: 600) {
                            ...GatsbySanityImageFluid
                        }
                    }
                  }
                  description
                  id
                }
              }
        }
  `);
  const { title } = node;
  const projectsCategories = projectCategories.nodes;
  let currentIndex = 0;
  return (
    <>
      <ProjectContainer>
          {projectsCategories.map(projectCategory => {
              currentIndex++;
                return (<ProjectItem key={projectCategory.id}>
                    {(currentIndex % 2 !== 0) ?
                        (<><ProjectItemContent>
                            <ProjectItemTitle>{projectCategory.title}</ProjectItemTitle>
                            <ProjectItemDescription>{projectCategory.description}</ProjectItemDescription>
                            <ProjectSellingpoints>
                                {projectCategory.sellingpoints.map((sellingpoint, index) => {
                                    return (<ProjectSellingpoint key={index}>
                                        {sellingpoint}
                                    </ProjectSellingpoint>)
                                })}
                            </ProjectSellingpoints>
                            <ProjectButtons>
                                <a href={'/contact'} title={'Offerte aanvragen'} className={'primary'}>Offerte aanvragen</a>
                                <a href={`/projecten#filter=${projectCategory.title}`} title={'Offerte aanvragen'} className={'normal'}>{projectCategory.title} bekijken</a>
                            </ProjectButtons>
                        </ProjectItemContent>
                        <ProjectItemImg>
                            <Img fluid={projectCategory.image.asset.fluid} alt={projectCategory.title} />
                        </ProjectItemImg></>
                        )
                        : (<><ProjectItemImg>
                                <Img fluid={projectCategory.image.asset.fluid} alt={projectCategory.title} />
                            </ProjectItemImg>
                            <ProjectItemContent>
                                <ProjectItemTitle>{projectCategory.title}</ProjectItemTitle>
                                <ProjectItemDescription>{projectCategory.description}</ProjectItemDescription>
                                <ProjectSellingpoints>
                                    {projectCategory.sellingpoints.map((sellingpoint, index) => {
                                        return (<ProjectSellingpoint key={index}>
                                            {sellingpoint}
                                        </ProjectSellingpoint>)
                                    })}
                                </ProjectSellingpoints>
                                <ProjectButtons>
                                    <a href={'/contact'} title={'Offerte aanvragen'} className={'primary'}>Offerte aanvragen</a>
                                    <a href={`/projecten#filter=${projectCategory.title}`} title={'Offerte aanvragen'} className={'normal'}>{projectCategory.title} bekijken</a>
                                </ProjectButtons>
                            </ProjectItemContent>
                            </>
                        )
                    }

                </ProjectItem>);
          })}
      </ProjectContainer>
    </>
  );
}
