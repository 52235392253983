import React from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  width: 75%;
  z-index: 1;
  padding-top: var(--spacing-l);
  font-size: 1.2rem;
  font-family: var(--font-small);

  .title {
    display: block;
    color: #000000;
    cursor: pointer;
    transition: 0.8s;
    padding-bottom: var(--spacing-m);
  }

  .title:hover {
    color: #bdbdbd;
  }

  .title + input {
    display: none;
  }

  .title + input + * {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s, overflow 0.8s;
  }

  .title + input:checked + * {
    max-height: ${(props) => props.height};
    overflow: auto;
    transition: max-height 0.8s, overflow 0.8s 0.8s;
  }
`;

const Content = styled.div``;

export default function SlideToggle(props) {
  const { title, content, elementNumber } = props;
  return (
    <SlideContainer {...props}>
      <label className="title" htmlFor={elementNumber}>
        {title}
      </label>
      <input id={elementNumber} type="checkbox" />
      <Content
        dangerouslySetInnerHTML={{
          __html: content.replaceAll('\n', '<br />'),
        }}
      />
    </SlideContainer>
  );
}
