import React from 'react';
import styled from 'styled-components';

const ParagraphContainer = styled.div`
  width: 75%;
  z-index: 1;
  padding-top: var(--spacing-l);
  margin: 0 auto;
  font-size: 1.2rem;
  font-family: var(--font-small);
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%; 
  }
`;

export default function Paragraph(node) {
  const { paragraph } = node;
  return <ParagraphContainer>{paragraph}</ParagraphContainer>;
}
