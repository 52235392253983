import React from 'react';
import styled from 'styled-components';
import logoSvg from '../assets/images/logo-chrisbakkerdak.png';
import SlideToggle from './SlideToggle';

const Introduction = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const IntroductionImage = styled.img`
  display: block;
  margin: 0 auto;
  object-fit: cover;
`;

const IntroductionTitle = styled.div`
  width: 50%;
  color: #bdbdbd;
  font-size: 2rem;
  font-weight: 500;
  font-family: var(--font-small);
  line-height: 0.9;
  text-align: left;
  margin-top: 8rem;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 5rem;
    width: 100%;
  }
`;

const IntroductionSubtitle = styled.h1`
  font-size: 3rem;
  width: 50%;
  line-height: 0.9;
  color: #000000;
  font-family: var(--font-small);
  text-align: left;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 5rem;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
`;

const IntroductionText = styled.div`
  color: #000000;
  font-size: 1.2rem;
  width: 50%;
  font-family: var(--font-small);
  text-align: left;
`;

export default function IntroBlock(node) {
  console.warn(node);
  let { title, subTitle } = node;

  // Moet nog allemaal in sanity gezet worden.
  title = 'Uw huid verdient';
  const extendTitle = `ook een 
  oppepper`;
  subTitle = `Sinds 2003 ben ik als 
  gediplomeerd 
  schoonheidsspecialiste, 
  visagiste en pedicure gestart 
  als, Schoonheidssalon Lineke.`;

  const treatmentTitle = `Pedicure:`;
  const treatmentContent = `Cosmetische pedicure: desinfectie voeten, knippen van de nagels, 
  nagels machinaal behandelen, nagelomgeving verzorgen en tot slot een verzorgende voetencrème.`;
  const treatmentHeight = `8rem`;
  const treatmentElementNumber = `_1`;

  const treatmentTitle2 = `Losse behandelingen:`;
  const treatmentContent2 = `Verven wenkbrauwen: 8,00 
  Verveln wimpers: 9,00 
  Paraffine handbehandeling: 14,50`;
  const treatmentHeight2 = `8rem`;
  const treatmentElementNumber2 = `_2`;

  return (
    <>
      <Introduction>
        <IntroductionImage
          src={logoSvg}
          alt={title}
          className="introduction__background"
        />
        <Wrapper>
          <IntroductionTitle>{title}</IntroductionTitle>
          <IntroductionSubtitle
            dangerouslySetInnerHTML={{
              __html: extendTitle.replace('\n', '<br />'),
            }}
          />
          <IntroductionText
            dangerouslySetInnerHTML={{
              __html: subTitle.replaceAll('\n', '<br />'),
            }}
          />
        </Wrapper>
        <SlideToggle
          title={treatmentTitle}
          content={treatmentContent}
          height={treatmentHeight}
          elementNumber={treatmentElementNumber}
        />
        <SlideToggle
          title={treatmentTitle2}
          content={treatmentContent2}
          height={treatmentHeight2}
          elementNumber={treatmentElementNumber2}
        />
      </Introduction>
    </>
  );
}
