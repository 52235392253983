import React from 'react';
import styled from 'styled-components';

const CallToActionContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background: var(--primary-color);
  margin-top: var(--spacing-m);
`;

const CallToActionFooter = styled.div`
  background: #f9f9f9;
  padding: var(--spacing-m) 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
`;

const FooterSlogan = styled.h3`
  font-family: var(--font-large);
  font-size: 2rem;
  line-height: 1;
  text-indent: -0.1rem;
  width: 90%;
  margin: 0 auto;
  color: var(--primary-color);
  text-align: center;
  margin-top: var(--spacing-m);
  text-transform: uppercase;
  font-style: italic;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 95%;
    font-size: 1.5rem;
  }
`;


export default function CallToActionSimple(node) {
  const { footerSlogan } = node;
  return (
    <>
      <CallToActionContainer>
        <CallToActionFooter>
          <FooterSlogan>{footerSlogan}</FooterSlogan>
        </CallToActionFooter>
      </CallToActionContainer>
    </>
  );
}
