import React, { useState } from 'react';
import styled from 'styled-components';
import useForm from '../utils/useForm';


const ContactFormContainer = styled.div`
  width: 100%;
  margin: var(--spacing-l) auto 0 auto;
  padding-bottom: 0;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
`;

const Introduction = styled.div`
  width: 75%;
  margin: var(--spacing-l) auto;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const Title = styled.h3`
  font-family: var(--font-small);
  font-size: 1.2rem;
  line-height: 1.4;
`;

const Content = styled.p`
  font-family: var(--font-small);
  font-size: 1.2rem;
  line-height: 1.4;
`;

const FormPanel = styled.div`
  width: 100%;
  margin: var(--spacing-m) auto;
  padding: 0 0 0 0;
  display: flex;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const ContentPanel = styled.div`
  flex-basis: 100%;
  background-color: #f9f9f9;
  padding: var(--spacing-l);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: var(--spacing-m);
  }
`;

const ContentTitle = styled(Title)`
  margin-bottom: var(--spacing-m);
`;

const FieldPanels = styled.div`
  display: flex;
  margin-right: var(--spacing-m);
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
    margin-right: 0;
  }
`;

const GroupPanel = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-family: var(--font-small);
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: var(--spacing-s);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const InputText = styled.input`
  border-radius: 5px;
  background: #fff;
  font-family: var(--font-small);
  font-size: 0.9rem;
  line-height: 1.4;
  margin: var(--spacing-s) var(--spacing-m) var(--spacing-s) 0;
  padding: var(--spacing-m) var(--spacing-s);
  border: 0;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const TextArea = styled.textarea`
  border-radius: 5px;
  background: #fff;
  font-family: var(--font-small);
  font-size: 0.9rem;
  line-height: 1.4;
  margin: var(--spacing-s) var(--spacing-m) var(--spacing-s) 0;
  padding: var(--spacing-m) var(--spacing-s);
  border: 0;
  height: 11.5rem;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const PrimaryButton = styled.input`
  display: block;
  font-family: var(--font-small);
  font-size: 1rem;
  margin: 0 var(--spacing-m) var(--spacing-s) 0;
  padding: var(--spacing-s) var(--spacing-m);
  text-align: center;
  text-decoration: none;
  width: auto;
  background-color: #0AA81C;
  color: var(--primary-color);
  border: 2px solid #0AA81C;
  border-radius: 2px;
  color: #FFF;
  cursor: pointer;
  transition: backgroundColor .4s, borderColor .2s;
  
  &:hover {
    background-color: transparent;
    color: #0AA81C;
  }
`;

const ThanksMessage = styled.h3`
  font-family: var(--font-large);
  font-size: 3rem;
  margin: var(--spacing-xxl) auto;
  text-align: center;
  font-style: italic;
  text-transform: uppercase;
`;

export default function ContactForm(node) {
  const { title, subTitle } = node;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const { values, updateValue } = useForm({
    name: '',
    email: '',
    phone: '',
    remark: '',
  });

  async function submitForm(event) {
    event.preventDefault();
    setLoading(true);

    const body = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      remark: values.remark,
    };
    const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const text = JSON.parse(await res.text());

    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setError('');
      setMessage(
        'Bedankt voor je bericht\n' +
          'Ik neem zo spoedig mogelijk contact met je op.'
      );
    }
  }

  if (message) {
    return (
      <>
        <ContactFormContainer>
          <Introduction>
            <Title>{title}</Title>
            <Content>{subTitle}</Content>
          </Introduction>
          <FormPanel>
            <ContentPanel>
              <ContentTitle>Contactformulier</ContentTitle>
              <ThanksMessage>{message}</ThanksMessage>
            </ContentPanel>
          </FormPanel>
        </ContactFormContainer>
      </>
    );
  }
  return (
    <>
      <ContactFormContainer>
        <Introduction>
          <Title>{title}</Title>
          <Content>{subTitle}</Content>
        </Introduction>
        <FormPanel>
          <ContentPanel>
            <ContentTitle>Contactformulier</ContentTitle>
            <FieldPanels>
              <GroupPanel>
                <Label>
                  Volledige naam *
                  <InputText
                    placeholder="John Doe"
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={updateValue}
                  />
                </Label>
                <Label>
                  Email *
                  <InputText
                    placeholder="johndoe@gmail.com"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={updateValue}
                  />
                </Label>
                <Label>
                  Telefoonnummer
                  <InputText
                    placeholder="06"
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={updateValue}
                  />
                </Label>
              </GroupPanel>
              <GroupPanel>
                <Label>
                  Vraag of opmerking
                  <TextArea
                    placeholder="Stel hier uw vraag"
                    name="remark"
                    onChange={updateValue}
                    value={values.remark}
                  />
                </Label>
                {error ? <p>Error: {error}</p> : ''}
                <PrimaryButton
                  type="button"
                  onClick={submitForm}
                  value={loading ? 'Verzenden...' : 'Verstuur'}
                  disabled={loading}
                />
              </GroupPanel>
            </FieldPanels>
          </ContentPanel>
        </FormPanel>
      </ContactFormContainer>
    </>
  );
}
