import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';
import {Link} from "gatsby";

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);

const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const HeroContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 3;
    height: 50rem;
`;

const HeroTitle = styled.h1`
  font-family: var(--font-large);
  font-size: 3rem;
  line-height: 1.4;
  text-shadow: 1px 1px rgba(0,0,0,0.75);
  width: 50%;
  text-align:center;
  z-index: 2;
  color: #FFF;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.2rem;
    width: 95%;
    text-align: left;
  }
`;

const HeroSubTitle = styled.h2`
  font-family: var(--font-small);
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.4;
  width: 50%;
  text-align:center;
  z-index: 2;
  color: #6898ED;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 0.9rem;
    text-align: left;
    width: 95%;
  }  
`;

const HeroButton = styled.div`
    background: #FFF;
    padding: var(--spacing-m);
    text-align:center;
    border-radius: 4px;
    border: 1px solid #202020;
    margin-top: var(--spacing-l);
    
    a {
        color: #202020;
        text-decoration: none;
        font-family: var(--font-small);
    }
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  
  &:before {
    content: " ";
    background: rgba(0, 0, 0, .4);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
`

const HeroMainImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

export default function Hero(node) {
  const { title, mainImage, subTitle, introduction } = node;

  const heroMainImage = mainImage ? (
    <HeroMainImage src={urlFor(mainImage).url()} alt={title} />
  ) : (
    <></>
  );
  return (
    <HeroContainer>
        <HeroContentWrapper>
            <HeroTitle>{title}</HeroTitle>
            <HeroSubTitle>{subTitle}</HeroSubTitle>
            {(introduction) ? (
                <HeroButton>
                    <Link to="/contact">
                        {introduction}
                    </Link>
                </HeroButton>)
                : ''
            }
        </HeroContentWrapper>
        <ImageContainer>
          {heroMainImage}
        </ImageContainer>
    </HeroContainer>
  );
}
